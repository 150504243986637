import Header from './header';
import Footer from './footer';
import React from 'react';
import useFetchData from '../hooks/vendor/fetchorder.js';
import { useAuth } from '../hooks/AuthContext';
import OrderDetail from './orderdetail';

function App() {
  const { vendorSessionId, setLoading } = useAuth();
  const vid = vendorSessionId;
  const { data } = useFetchData({ vid, setLoading });

  return (
    <div className="App">
      <Header />
      <div className="midouter" id="cart">
        <div className="container">
          <div className="cart-page">
            <div className='d-flex justify-content-between'>
              <h3>Order Details ({data.length})</h3>
            </div>

            {data.length > 0 ? (
              <div className="order-detail-list">
                {data.map(item => (
                  <div key={item.order_id} className="order-detail-wrapper mb-4">
                    <OrderDetail orderid={item.order_id} />
                  </div>
                ))}
              </div>
            ) : (
              <p>No orders found.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
