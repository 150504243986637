import React from 'react';
import useFetchData from '../hooks/vendor/fetchorderdetail.js';
import { FaRupeeSign, FaWhatsapp } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';

function OrderDetail({ orderid }) {
  const { setLoading } = useAuth();
  const { data, setData } = useFetchData({ orderid, setLoading });

  // Delete whole order
  const handleDeleteOrder = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete the entire order?");
    if (!confirmDelete) return;

    try {
      const response = await axios.post(
        `https://bulkify.in/api/vendor/delete-order.php?formtype=deleteOrder&oid=${orderid}`
      );

      if (response.data.Massage === "Successfully Delete") {
        alert("Order deleted successfully.");
        window.history.back(); // Or navigate to another page
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  if (!data.length) return null;

  return (
    <div className='previousorder-bx'>
      <div className="order-detail-expanded">
        {data.map(order => (
          <div key={order.order_id} className="order-card">
            <div className="products-list d-flex mb-2 justify-content-between">
              <b>Order ID: {order.order_id}</b>
              <b>Date: {order.added_date}</b>
              <b>User Mob.: {order.usermob}</b>

              <button className="delete saw ms-2" onClick={handleDeleteOrder}>
            x
              </button>
            </div>

            {order.image && (
              <a
                href={`https://bulkify.in/photo/category/${order.image}`}
                download={order.image}
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={`https://bulkify.in/photo/category/${order.image}`}
                  alt={order.image}
                  className="product-image"
                />
              </a>
            )}

            <div className="products-list">
              {order.basket?.map((item, index) => {
                const imageUrl = `https://bulkify.in/photo/product/${item.imgpath1}`;
                const message = encodeURIComponent(
                  `${imageUrl}`
                );
                const whatsappUrl = `https://wa.me/+91${order.usermob}?text=${message}`;

                return (
                  <div className="product-item d-flex justify-content-between" key={index}>
                    <img
                      src={imageUrl}
                      alt={item.product_name}
                      className="product-image"
                    />
                    <div className="product-info">
                      <p className="product-name">{item.product_name}</p>
                      {item.price && (
                        <p className="product-price">
                          <b><FaRupeeSign />{item.total_final_price}</b>
                          {item.discount > 0 && (
                            <>
                              &nbsp; ({item.discount}% off) &nbsp;
                              <span style={{ textDecoration: "line-through" }}>
                                <FaRupeeSign />{item.price}
                              </span>
                            </>
                          )}
                        </p>
                      )}
                      <p className="product-quantity">Quantity: {item.quantity}</p>
                    </div>

                    <a 
                      href={whatsappUrl}
                      className="whatsappsend ms-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp />
                      
                    </a>

                 
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderDetail;
